import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";

import Forms from "./forms/Forms";

import Layout from "./screens/Layout";

import About from "./components/About";
import Login from "./screens/Login";
import RegionScreen from "./screens/RegionScreen";
import GroupScreen from "./screens/GroupScreen";
import NoAccess from "./screens/NoAccess";
import ContactUs from "./forms/federation/ContactUs";
import FedEvents from "./events/FedEvents";
import RegEvents from "./events/RegEvents";

// Federation AGM
import FedAGM from "./forms/federation/FedAGM";
import FedAGMForm from "./forms/federation/FedAGMForm";
import FedAGMEditForm from "./forms/federation/FedAGMEditForm";
import FedAGMResponse from "./forms/federation/FedAGMResponse";

// Federation CCM
import FedCCM from "./forms/federation/FedCCM";
import FedCCMForm from "./forms/federation/FedCCMForm";
import FedCCMEditForm from "./forms/federation/FedCCMEditForm";
import FedCCMResponse from "./forms/federation/FedCCMResponse";

// Federation ID Meet
import FedIDMeet from "./forms/federation/FedIDMeet";
import FedIDMeetForm from "./forms/federation/FedIDMeetForm";
import FedIDMeetEditForm from "./forms/federation/FedIDMeetEditForm";
import FedIDMeetResponse from "./forms/federation/FedIDMeetResponse";

// Federation OB Meet
import FedOBMeet from "./forms/federation/FedOBMeet";
import FedOBMeetForm from "./forms/federation/FedOBMeetForm";
import FedOBMeetEditForm from "./forms/federation/FedOBMeetEditForm";
import FedOBMeetResponse from "./forms/federation/FedOBMeetResponse";

// Region AGM
import RegionAGM from "./forms/region/RegionAGM";
import RegionAGMForm from "./forms/region/RegionAGMForm";
import RegionAGMEditForm from "./forms/region/RegionAGMEditForm";
import RegionAGMResponse from "./forms/region/RegionAGMResponse";

// Region Commitee Meet
import RegionCommitteeMeet from "./forms/region/RegionCommiteeMeet";
import RegionCommiteeMeetForm from "./forms/region/RegionCommiteeMeetForm";
import RegionCommiteeMeetEditForm from "./forms/region/RegionCommiteeMeetEditForm";
import RegionCommiteeMeetResponse from "./forms/region/RegionCommiteeMeetResponse";

// Region OB Meet
import RegionOBMeet from "./forms/region/RegionOBMeet";
import RegionOBMeetForm from "./forms/region/RegionOBMeetForm";
import RegionOBMeetResponse from "./forms/region/RegionOBMeetResponse";
import RegionOBMeetEditForm from "./forms/region/RegionOBMeetEditForm";

// Group AGM
import GroupAGM from "./forms/group/GroupAGM";
import GroupAGMForm from "./forms/group/GroupAGMForm";
import GroupAGMEditForm from "./forms/group/GroupAGMEditForm";
import GroupAGMResponse from "./forms/group/GroupAGMResponse";

// Group Commitee Meet
import GroupCommitteeMeet from "./forms/group/GroupCommitteeMeet";
import GroupCommitteeMeetForm from "./forms/group/GroupCommitteeMeetForm";
import GroupCommitteeMeetEditForm from "./forms/group/GroupCommitteeMeetEditForm";
import GroupCommitteeMeetResponse from "./forms/group/GroupCommitteeMeetResponse";

// Group EGM
import GroupEGM from "./forms/group/GroupEGM";
import GroupEGMForm from "./forms/group/GroupEGMForm";
import GroupEGMEditForm from "./forms/group/GroupEGMEditForm";
import GroupEGMResponse from "./forms/group/GroupEGMResponse";

// Group Forum Registraion
import GroupForumRegistration from "./forms/group/GroupForumRegistration";
import GroupForumRegistrationForm from "./forms/group/GroupForumRegistrationForm";
import GroupForumRegistrationEdit from "./forms/group/GroupForumRegistrationEditForm";
import GroupForumRegistrationResponse from "./forms/group/GroupForumRegistrationResponse";

// Group Membership
import GroupMembership from "./forms/group/GroupMembership";
import GroupMembershipForm from "./forms/group/GroupMembershipForm";

// Group FORM A
import GroupFormA from "./forms/group/GroupFormA";
import GroupFormAForm from "./forms/group/GroupFormAForm";
import GroupFormAResponse from "./forms/group/GroupFormAResponse";

// Group FORM B
import FormBApp from "./forms/group/FormB/FormBApp";
import GroupFormBResponse from "./forms/group/GroupFormBResponse";

// Sangini Committee Meet
import SanginiCommitteeMeet from "./forms/sangini/SanginiCommitteeMeet";
import SanginiCommitteeMeetForm from "./forms/sangini/SanginiCommitteeMeetForm";
import SanginiCommitteeMeetResponse from "./forms/sangini/SanginiCommitteeMeetResponse";

// Sangini Forum Registraion
import SanginiForumRegistration from "./forms/sangini/SanginiForumRegistration";
import SanginiForumRegistrationForm from "./forms/sangini/SanginiForumRegistrationForm";

// Sangini Membership
import SanginiMembership from "./forms/sangini/SanginiMembership";
import SanginiMembershipForm from "./forms/sangini/SanginiMembershipForm";

// MBS
import MbsNomination from "./forms/mbs/MbsNomination";
import MbsNominationResponse from "./forms/mbs/MbsNominationResponse";

import MangalyatraScreen from "./mangalyatra/MangalyatraScreen";

import RegionList from "./components/ChatBot/RegionList";
import GroupMembershipResponse from "./forms/group/GroupMembershipResponse";
import GroupFormB from "./forms/group/GroupFormB";
import FedAER from "./forms/federation/FedAER";
import FedAERForm from "./forms/federation/FedAERForm";
import RegionNomintaionOBForm from "./forms/region/RegionNomintaionOBForm";
import RegionNomintaionOBResponse from "./forms/region/RegionNomintaionOBResponse";
import FedNomintaionIDForm from "./forms/federation/FedNomintaionIDForm";
import FedNomintaionIDResponse from "./forms/federation/FedNomintaionIDResponse";
import FormerPresident from "./screens/FormerPresident";
import Tutorials from "./screens/Tutorials";
import TutorialScreenEng from "./screens/TutorialScreenEng";
import TutorialScreenHin from "./screens/TutorialScreenHin";
import Samvid from "./screens/Samvid";
import Kartavya from "./screens/Kartavya";
import FedIDScreen from "./screens/FedIDScreen";
import FedOBScreen from "./screens/FedOBScreen";
import Founders from "./screens/Founders";
import EngMy1 from "./mangalyatra/english/EngMy1";
import MannKiBaat from "./screens/MannKiBaat";
import GujMy1 from "./mangalyatra/gujarati/GujMy1";
import HinMy1 from "./mangalyatra/hindi/HinMy1";
import Testimonials from "./screens/Testimonials";
import MediaCoverages from "./screens/MediaCoverages";
import FedAEREditForm from "./forms/federation/FedAEREditForm";
import FedEventScreen from "./events/FedEventScreen";
import SgnEventScreen from "./events/SgnEventScreen";
import FedCommittee from "./screens/FedCommittee";
import Constitution from "./screens/Constitution";
import Ventures from "./screens/Ventures";
import Oath from "./screens/Oath";
import RegChairman from "./screens/RegChairman";
import EduFormApp from "./forms/jsgff/EduFormApp";
import EduLoanResponse from "./forms/jsgff/EduLoanResponse";
import SgnEvents from "./events/SgnEvents";
import AskJsg from "./ventures/AskJsg";
import Eminence from "./ventures/Eminence";
import Gurukul from "./ventures/Gurukul";
import Udaan from "./ventures/Udaan";
import Runbhoomi from "./ventures/Runbhoomi";
import Strot from "./ventures/Strot";
import ZoomBG from "./screens/zoom/ZoomBG";
import ZoomBGFed from "./screens/zoom/ZoomBGFed";
import Aashray from "./ventures/Aashray";
import SgnCommittee from "./screens/SgnCommittee";
import GroupMangalyatraForm from "./forms/group/GroupMangalyatraForm";
import GroupMangalyatraResponse from "./forms/group/GroupMangalyatraResponse";
import SanginiMangalyatraForm from "./forms/sangini/SanginiMangalyatraForm";
import SanginiMangalyatraResponse from "./forms/sangini/SanginiMangalyatraResponse";
import RegionMangalyatraForm from "./forms/region/RegionMangalyatraForm";
import RegionMangalyatraResponse from "./forms/region/RegionMangalyatraResponse";
import SanginiMangalyatra from "./forms/sangini/SanginiMangalyatra";
import RegionMangalyatra from "./forms/region/RegionMangalyatra";
import GroupMangalyatra from "./forms/group/GroupMangalyatra";
import GrpEventScreen from "./events/GrpEventScreen";
import ConstitutionAndBylaws from "./ventures/ConstitutionAndBylaws";
import Pari from "./ventures/Pari";
import LogoDownloads from "./screens/LogoDownloads";
import JsgBank from "./screens/JsgBank";
import ThemeSong from "./screens/ThemeSong";
import EditProfile from "./screens/EditProfile";
import GroupFormAEditForm from "./forms/group/GroupFormAEditForm";
import GroupBanner from "./screens/banner/GroupBanner";
import RegionBanner from "./screens/banner/RegionBanner";
import GrpEventUpload from "./events/GrpEventUpload";
import FedEventUpload from "./events/FedEventUpload";
import SgnEventUpload from "./events/SgnEventUpload";
import RegEventUpload from "./events/RegEventUpload";
import ObProjects from "./events/ObProjects";
import ObProjectScreen from "./events/ObProjectScreen";
import ObProjectUpload from "./events/ObProjectUpload";
import ObWiseProjects from "./events/ObWiseProjects";
import SgnFormBApp from "./forms/sangini/SanginiFormB/SgnFormBApp";
import SgnFormB from "./forms/sangini/SgnFormB";
import SgnFormA from "./forms/sangini/SgnFormA";
import SgnFormAForm from "./forms/sangini/SgnFormAForm";
import SgnFormAEditForm from "./forms/sangini/SgnFormAEditForm";
import SgnFormBResponse from "./forms/sangini/SgnFormBResponse";
import SgnFormAResponse from "./forms/sangini/SgnFormAResponse";
import EngMy2 from "./mangalyatra/english/EngMy2";
import GujMy2 from "./mangalyatra/gujarati/GujMy2";
import HinMy2 from "./mangalyatra/hindi/HinMy2";
import PariForm from "./forms/pari/PariForm";
import PariResponse from "./forms/pari/PariResponse";
import GrpQ1Form from "./forms/eminence/1st Quarter/GrpQ1Form";
import GrpQ1Response from "./forms/eminence/1st Quarter/GrpQ1Response";
import RegQ1Form from "./forms/eminence/1st Quarter/RegQ1Form";
import RegQ1Response from "./forms/eminence/1st Quarter/RegQ1Response";
import GrpQ1List from "./forms/eminence/1st Quarter/GrpQ1List";
import RegQ1List from "./forms/eminence/1st Quarter/RegQ1List";
import ObH1Form from "./forms/eminence/halfYearly/ObH1Form";
import ObH1Response from "./forms/eminence/halfYearly/ObH1Response";
import ObH1List from "./forms/eminence/halfYearly/ObH1List";
import ConvenorH1Form from "./forms/eminence/halfYearly/ConvenorH1Form";
import ConvenorH1Response from "./forms/eminence/halfYearly/ConvenorH1Response";
import ConvenorH1List from "./forms/eminence/halfYearly/ConvenorH1List";
import AskJsgRegistration from "./ventures/AskJsgRegistration";
import UdaanForm from "./forms/udaan/UdaanForm";
import UdaanResponse from "./forms/udaan/UdaanResponse";
import RegEventScreen from "./events/RegEventScreen";
import SgnRegionScreen from "./screens/SgnRegionScreen";
import SgnGroupScreen from "./screens/SgnGroupScreen";
import FormBEditApp from "./forms/group/EditFormB/FormBEditApp";
import SgnFormBEditApp from "./forms/sangini/SgnEditFormB/SgnFormBEditApp";
import SgnEventEdit from "./events/SgnEventEdit";
import RegEventEdit from "./events/RegEventEdit";
import GrpEventEdit from "./events/GrpEventEdit";
import FedEventEdit from "./events/FedEventEdit";
import GrpQ2List from "./forms/eminence/2nd Quarter/GrpQ2List";
import GrpQ2Form from "./forms/eminence/2nd Quarter/GrpQ2Form";
import GrpQ2Response from "./forms/eminence/2nd Quarter/GrpQ2Response";
import RegQ2List from "./forms/eminence/2nd Quarter/RegQ2List";
import RegQ2Form from "./forms/eminence/2nd Quarter/RegQ2Form";
import RegQ2Response from "./forms/eminence/2nd Quarter/RegQ2Response";
import EngMy3 from "./mangalyatra/english/EngMy3";
import GujMy3 from "./mangalyatra/gujarati/GujMy3";
import HinMy3 from "./mangalyatra/hindi/HinMy3";
import EngMy4 from "./mangalyatra/english/EngMy4";
import GujMy4 from "./mangalyatra/gujarati/GujMy4";
import HinMy4 from "./mangalyatra/hindi/HinMy4";
import EngMy5 from "./mangalyatra/english/EngMy5";
import GujMy5 from "./mangalyatra/gujarati/GujMy5";
import HinMy5 from "./mangalyatra/hindi/HinMy5";
import RegQ3List from "./forms/eminence/3rd Quarter/RegQ3List";
import RegQ3Form from "./forms/eminence/3rd Quarter/RegQ3Form";
import RegQ3Response from "./forms/eminence/3rd Quarter/RegQ3Response";
import GrpQ3List from "./forms/eminence/3rd Quarter/GrpQ3List";
import GrpQ3Form from "./forms/eminence/3rd Quarter/GrpQ3Form";
import GrpQ3Response from "./forms/eminence/3rd Quarter/GrpQ3Response";
import HinMy6 from "./mangalyatra/hindi/HinMy6";
import EngMy6 from "./mangalyatra/english/EngMy6";
import GujMy6 from "./mangalyatra/gujarati/GujMy6";
import NavkarMahamantra from "./screens/NavkarMahamantra";
import VideoMahilaDivasMangalyatra from "./screens/VideoMahilaDivasMangalyatra";
import GrpQ4List from "./forms/eminence/4th Quarter/GrpQ4List";
import GrpQ4Form from "./forms/eminence/4th Quarter/GrpQ4Form";
import GrpQ4Response from "./forms/eminence/4th Quarter/GrpQ4Response";
import RegQ4List from "./forms/eminence/4th Quarter/RegQ4List";
import RegQ4Form from "./forms/eminence/4th Quarter/RegQ4Form";
import RegQ4Response from "./forms/eminence/4th Quarter/RegQ4Response";
import EngMy7 from "./mangalyatra/english/EngMy7";
import HinMy7 from "./mangalyatra/hindi/HinMy7";
import GujMy7 from "./mangalyatra/gujarati/GujMy7";
import Anmol from "./screens/Anmol";
import VideoAnmol from "./screens/VideoAnmol";
import EngMy8 from "./mangalyatra/english/EngMy8";
import GujMy8 from "./mangalyatra/gujarati/GujMy8";
import HinMy8 from "./mangalyatra/hindi/HinMy8";
import ConstitutionIndex from "./screens/ConstitutionIndex";
import Constitution16 from "./screens/Constitution16";
import GrpQ5Form from "./forms/eminence/5th Quarter/GrpQ5Form";
import GrpQ5List from "./forms/eminence/5th Quarter/GrpQ5List";
import GrpQ5Response from "./forms/eminence/5th Quarter/GrpQ5Response";
import Mkb1 from "./mannKiBaat/Mkb1";
import Mkb2 from "./mannKiBaat/Mkb2";
import Mkb3 from "./mannKiBaat/Mkb3";
import Mkb4 from "./mannKiBaat/Mkb4";
import Mkb5 from "./mannKiBaat/Mkb5";
import Mkb6 from "./mannKiBaat/Mkb6";
import RegQ5List from "./forms/eminence/5th Quarter/RegQ5List";
import RegQ5Form from "./forms/eminence/5th Quarter/RegQ5Form";
import RegQ5Response from "./forms/eminence/5th Quarter/RegQ5Response";
import EngMy9 from "./mangalyatra/english/EngMy9";
import HinMy9 from "./mangalyatra/hindi/HinMy9";
import GujMy9 from "./mangalyatra/gujarati/GujMy9";
import EngMy10 from "./mangalyatra/english/EngMy10";
import HinMy10 from "./mangalyatra/hindi/HinMy10";
import GujMy10 from "./mangalyatra/gujarati/GujMy10";
import SplEdition from "./mangalyatra/english/SplEdition";
import Vidya from "./ventures/Vidya";
import VidyaForm from "./forms/vidya/VidyaForm";
import VidyaResponse from "./forms/vidya/VidyaResponse";
import RegQ6List from "./forms/eminence/6th Quarter/RegQ6List";
import RegQ6Form from "./forms/eminence/6th Quarter/RegQ6Form";
import RegQ6Response from "./forms/eminence/6th Quarter/RegQ6Response";
import GrpQ6List from "./forms/eminence/6th Quarter/GrpQ6List";
import GrpQ6Form from "./forms/eminence/6th Quarter/GrpQ6Form";
import GrpQ6Response from "./forms/eminence/6th Quarter/GrpQ6Response";
import VidyaList from "./forms/vidya/VidyaList";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} exact>
            <Route path="" element={<HomeScreen />} />
            <Route path="not-allowed" element={<NoAccess />} />
            <Route path="aboutus" element={<About />} />
            <Route path="contactus" element={<ContactUs />} />
            <Route path="regions" element={<RegionList />} />
            <Route path="region/:regName" element={<RegionScreen />} />
            <Route path="group/:grpName" element={<GroupScreen />} />

            <Route
              path="sangini/region/:regName"
              element={<SgnRegionScreen />}
            />
            <Route path="sangini/group/:grpName" element={<SgnGroupScreen />} />

            <Route path="user/edit-profile/:id" element={<EditProfile />} />

            <Route
              path="mangalyatra/mahiladivas-video"
              element={<VideoMahilaDivasMangalyatra />}
            />

            <Route path="fed/bank-details" element={<JsgBank />} />
            <Route path="theme-song" element={<ThemeSong />} />
            <Route path="navkar-mahamantra" element={<NavkarMahamantra />} />
            <Route path="logo-downloads" element={<LogoDownloads />} />
            <Route path="testimonials" element={<Testimonials />} />
            <Route path="media-coverages" element={<MediaCoverages />} />
            <Route path="founders" element={<Founders />} />
            <Route path="mann-ki-baat" element={<MannKiBaat />} />
            <Route path="oath-of-office" element={<Oath />} />

            <Route path="former-presidents" element={<FormerPresident />} />
            <Route path="fed/ob/:term" element={<FedOBScreen />} />
            <Route path="fed/id/:term" element={<FedIDScreen />} />
            <Route path="fed/committee/:term" element={<FedCommittee />} />
            <Route path="reg/chairmen/:term" element={<RegChairman />} />

            <Route path="sgn/committee/:term" element={<SgnCommittee />} />

            <Route path="fed/ob-projects" element={<ObProjects />} />
            <Route path="fed/ob-project/:id" element={<ObProjectScreen />} />
            <Route
              path="fed/:user_id/ob-projects/"
              element={<ObWiseProjects />}
            />
            <Route
              path="upload/fed/ob-project/"
              element={<ObProjectUpload />}
            />

            <Route path="fed/event/:id" element={<FedEventScreen />} />
            <Route path="grp/event/:id" element={<GrpEventScreen />} />
            <Route path="reg/event/:id" element={<RegEventScreen />} />
            <Route path="sgn/event/:id" element={<SgnEventScreen />} />

            <Route path="upload/fed/event/" element={<FedEventUpload />} />
            <Route path="upload/reg/event/" element={<RegEventUpload />} />
            <Route path="upload/grp/event/" element={<GrpEventUpload />} />
            <Route path="upload/sgn/event/" element={<SgnEventUpload />} />

            <Route path="edit/fed/event/:id" element={<FedEventEdit />} />
            <Route path="edit/reg/event/:id" element={<RegEventEdit />} />
            <Route path="edit/grp/event/:id" element={<GrpEventEdit />} />
            <Route path="edit/sgn/event/:id" element={<SgnEventEdit />} />

            <Route path="ventures" element={<Ventures />} />
            <Route path="ventures/aashray" element={<Aashray />} />
            <Route path="ventures/ask-jsg" element={<AskJsg />} />
            <Route
              path="ventures/ask-jsg/registration"
              element={<AskJsgRegistration />}
            />
            <Route
              path="ventures/constitution-&-bylaws"
              element={<ConstitutionAndBylaws />}
            />
            <Route path="ventures/eminence" element={<Eminence />} />
            <Route path="ventures/gurukul" element={<Gurukul />} />
            <Route path="ventures/pari" element={<Pari />} />
            <Route path="ventures/runbhoomi" element={<Runbhoomi />} />
            <Route path="ventures/strot" element={<Strot />} />
            <Route path="ventures/udaan" element={<Udaan />} />
            <Route path="ventures/vidya" element={<Vidya />} />

            <Route path="zoom-background/:name" element={<ZoomBG />} />
            <Route path="fed/zoom-background/" element={<ZoomBGFed />} />

            <Route path="banner-download/group/" element={<GroupBanner />} />
            <Route path="banner-download/region/" element={<RegionBanner />} />

            <Route path="tutorials" element={<Tutorials />} />
            <Route path="tutorial/eng/:id" element={<TutorialScreenEng />} />
            <Route path="tutorial/hin/:id" element={<TutorialScreenHin />} />

            {/* Events */}
            <Route path="reg/events" element={<RegEvents />} />
            <Route path="fed/events" element={<FedEvents />} />
            <Route path="sgn/events" element={<SgnEvents />} />
            <Route path="forms" element={<Forms />} />
            <Route path="login" element={<Login />} />

            {/* Books */}
            <Route path="/samvid" element={<Samvid />} />
            <Route path="/kartavya" element={<Kartavya />} />
            <Route path="/constitution" element={<Constitution />} />
            <Route
              path="/constitution-16-20-2020"
              element={<Constitution16 />}
            />
            <Route path="/constitution-index" element={<ConstitutionIndex />} />
            <Route path="/anmol" element={<Anmol />} />
            <Route path="/video/anmol" element={<VideoAnmol />} />

            {/* Mann Ki baat */}
            <Route path="/mann-ki-baat/1" element={<Mkb1 />} />
            <Route path="/mann-ki-baat/2" element={<Mkb2 />} />
            <Route path="/mann-ki-baat/3" element={<Mkb3 />} />
            <Route path="/mann-ki-baat/4" element={<Mkb4 />} />
            <Route path="/mann-ki-baat/5" element={<Mkb5 />} />
            {/* <Route path="/mann-ki-baat/6" element={<Mkb6 />} /> */}

            {/* Manglayatra */}
            <Route path="/mangalyatra" element={<MangalyatraScreen />} />
            <Route path="/mangalyatra/eng-1" element={<EngMy1 />} />
            <Route path="/mangalyatra/guj-1" element={<GujMy1 />} />
            <Route path="/mangalyatra/hin-1" element={<HinMy1 />} />
            <Route path="/mangalyatra/eng-2" element={<EngMy2 />} />
            <Route path="/mangalyatra/guj-2" element={<GujMy2 />} />
            <Route path="/mangalyatra/hin-2" element={<HinMy2 />} />
            <Route path="/mangalyatra/eng-3" element={<EngMy3 />} />
            <Route path="/mangalyatra/guj-3" element={<GujMy3 />} />
            <Route path="/mangalyatra/hin-3" element={<HinMy3 />} />
            <Route path="/mangalyatra/eng-4" element={<EngMy4 />} />
            <Route path="/mangalyatra/guj-4" element={<GujMy4 />} />
            <Route path="/mangalyatra/hin-4" element={<HinMy4 />} />
            <Route path="/mangalyatra/eng-5" element={<EngMy5 />} />
            <Route path="/mangalyatra/guj-5" element={<GujMy5 />} />
            <Route path="/mangalyatra/hin-5" element={<HinMy5 />} />
            <Route path="/mangalyatra/eng-6" element={<EngMy6 />} />
            <Route path="/mangalyatra/hin-6" element={<HinMy6 />} />
            <Route path="/mangalyatra/guj-6" element={<GujMy6 />} />
            <Route path="/mangalyatra/eng-7" element={<EngMy7 />} />
            <Route path="/mangalyatra/hin-7" element={<HinMy7 />} />
            <Route path="/mangalyatra/guj-7" element={<GujMy7 />} />
            <Route path="/mangalyatra/eng-8" element={<EngMy8 />} />
            <Route path="/mangalyatra/hin-8" element={<HinMy8 />} />
            <Route path="/mangalyatra/guj-8" element={<GujMy8 />} />
            <Route path="/mangalyatra/eng-9" element={<EngMy9 />} />
            <Route path="/mangalyatra/hin-9" element={<HinMy9 />} />
            <Route path="/mangalyatra/guj-9" element={<GujMy9 />} />
            <Route path="/mangalyatra/eng-10" element={<EngMy10 />} />
            <Route path="/mangalyatra/hin-10" element={<HinMy10 />} />
            <Route path="/mangalyatra/guj-10" element={<GujMy10 />} />
            <Route
              path="/mangalyatra/special-edition"
              element={<SplEdition />}
            />

            {/* Federation Forms */}
            <Route path="reg-mangalyatra" element={<RegionMangalyatra />} />
            <Route
              path="form/reg-mangalyatra"
              element={<RegionMangalyatraForm />}
            />
            <Route
              path="reg-mangalyatra-response/:id"
              element={<RegionMangalyatraResponse />}
            />

            <Route path="sgn-mangalyatra" element={<SanginiMangalyatra />} />
            <Route
              path="form/sgn-mangalyatra"
              element={<SanginiMangalyatraForm />}
            />
            <Route
              path="sgn-mangalyatra-response/:id"
              element={<SanginiMangalyatraResponse />}
            />

            <Route path="grp-mangalyatra" element={<GroupMangalyatra />} />
            <Route
              path="grp-mangalyatra-response/:id"
              element={<GroupMangalyatraResponse />}
            />
            <Route
              path="form/grp-mangalyatra"
              element={<GroupMangalyatraForm />}
            />

            <Route path="fed-idmeet" element={<FedIDMeet />} />
            <Route path="form/fed-idmeet" element={<FedIDMeetForm />} />
            <Route
              path="/form/fed-idmeet/:id/edit"
              element={<FedIDMeetEditForm />}
            />
            <Route
              path="fed-idmeet-response/:id"
              element={<FedIDMeetResponse />}
            />
            <Route path="fed-obmeet" element={<FedOBMeet />} />
            <Route path="form/fed-obmeet" element={<FedOBMeetForm />} />
            <Route
              path="form/fed-obmeet/:id/edit"
              element={<FedOBMeetEditForm />}
            />
            <Route
              path="fed-obmeet-response/:id"
              element={<FedOBMeetResponse />}
            />

            <Route path="form/jsgff-education-loan" element={<EduFormApp />} />
            <Route
              path="jsgff-education-loan/response/:id"
              element={<EduLoanResponse />}
            />

            <Route path="fed-aer" element={<FedAER />} />
            <Route path="form/fed-aer" element={<FedAERForm />} />
            <Route path="form/fed-aer/:id/edit" element={<FedAEREditForm />} />
            <Route path="fed-agm" element={<FedAGM />} />
            <Route path="form/fed-agm" element={<FedAGMForm />} />
            <Route path="form/fed-agm/:id/edit" element={<FedAGMEditForm />} />
            <Route path="fed-agm-response/:id" element={<FedAGMResponse />} />
            <Route path="fed-ccm" element={<FedCCM />} />
            <Route path="form/fed-ccm" element={<FedCCMForm />} />
            <Route path="form/fed-ccm/:id/edit" element={<FedCCMEditForm />} />
            <Route path="fed-ccm-response/:id" element={<FedCCMResponse />} />
            <Route
              path="form/fed-nomination-id"
              element={<FedNomintaionIDForm />}
            />
            <Route
              path="fed-nomination-id-response/:id"
              element={<FedNomintaionIDResponse />}
            />

            {/* Region Forms */}
            <Route path="reg-agm" element={<RegionAGM />} />
            <Route path="form/ragm" element={<RegionAGMForm />} />
            <Route path="form/ragm/:id/edit" element={<RegionAGMEditForm />} />
            <Route path="ragm-response/:id" element={<RegionAGMResponse />} />

            <Route
              path="reg-committee-meet"
              element={<RegionCommitteeMeet />}
            />
            <Route
              path="form/rcommitteemeet"
              element={<RegionCommiteeMeetForm />}
            />
            <Route
              path="form/rcommitteemeet/:id/edit"
              element={<RegionCommiteeMeetEditForm />}
            />
            <Route
              path="rcommitteemeet-response/:id"
              element={<RegionCommiteeMeetResponse />}
            />

            <Route
              path="form/reg-nomination-ob"
              element={<RegionNomintaionOBForm />}
            />
            <Route
              path="reg-nomination-ob-response/:id"
              element={<RegionNomintaionOBResponse />}
            />

            <Route path="reg-obmeet" element={<RegionOBMeet />} />
            <Route path="form/robmeet" element={<RegionOBMeetForm />} />
            <Route
              path="form/robmeet/:id/edit"
              element={<RegionOBMeetEditForm />}
            />
            <Route
              path="robmeet-response/:id"
              element={<RegionOBMeetResponse />}
            />

            {/* Group Forms */}
            <Route path="grp-form-a" element={<GroupFormA />} />
            <Route path="form/grp-form-a" element={<GroupFormAForm />} />
            <Route
              path="form/grp-form-a/:id/edit"
              element={<GroupFormAEditForm />}
            />
            <Route
              path="grp-form-a-response/:id"
              element={<GroupFormAResponse />}
            />

            <Route path="grp-form-b" element={<GroupFormB />} />
            <Route path="form/grp-form-b" element={<FormBApp />} />
            <Route path="form/grp-form-b/:id/edit" element={<FormBEditApp />} />
            <Route
              path="grp-form-b-response/:id"
              element={<GroupFormBResponse />}
            />

            <Route path="grpagm" element={<GroupAGM />} />
            <Route path="form/grpagm" element={<GroupAGMForm />} />
            <Route path="form/grpagm/:id/edit" element={<GroupAGMEditForm />} />
            <Route path="grpagm-response/:id" element={<GroupAGMResponse />} />

            <Route path="grpcommitteemeet" element={<GroupCommitteeMeet />} />
            <Route
              path="form/grpcommitteemeet"
              element={<GroupCommitteeMeetForm />}
            />
            <Route
              path="form/grpcommitteemeet/:id/edit"
              element={<GroupCommitteeMeetEditForm />}
            />
            <Route
              path="grpcommitteemeet-response/:id"
              element={<GroupCommitteeMeetResponse />}
            />

            <Route path="grpegm" element={<GroupEGM />} />
            <Route path="form/grpegm" element={<GroupEGMForm />} />
            <Route path="form/grpegm/:id/edit" element={<GroupEGMEditForm />} />
            <Route path="grpegm-response/:id" element={<GroupEGMResponse />} />

            <Route
              path="grp-forum-registraion"
              element={<GroupForumRegistration />}
            />
            <Route
              path="form/grp-forum-registraion"
              element={<GroupForumRegistrationForm />}
            />
            <Route
              path="form/grp-forum-registraion/:id/edit"
              element={<GroupForumRegistrationEdit />}
            />
            <Route
              path="grp-forum-registraion-response/:id"
              element={<GroupForumRegistrationResponse />}
            />

            <Route path="grp-membership-form" element={<GroupMembership />} />
            <Route
              path="form/grp-membership-form"
              element={<GroupMembershipForm />}
            />
            <Route
              path="grp-membership-response/:id"
              element={<GroupMembershipResponse />}
            />

            {/* Sangini Forms */}
            <Route
              path="sgn-committee-meet"
              element={<SanginiCommitteeMeet />}
            />
            <Route
              path="form/sgn-committeemeet"
              element={<SanginiCommitteeMeetForm />}
            />
            <Route
              path="sgncommitteemeet-response/:id"
              element={<SanginiCommitteeMeetResponse />}
            />

            <Route
              path="sgn-forum-registraion"
              element={<SanginiForumRegistration />}
            />
            <Route
              path="form/sgn-forum-registraion"
              element={<SanginiForumRegistrationForm />}
            />
            <Route
              path="sgn-forum-registraion-response/:id"
              element={<GroupForumRegistrationResponse />}
            />

            <Route path="sgn-membership-form" element={<SanginiMembership />} />
            <Route
              path="form/sgn-membership-form"
              element={<SanginiMembershipForm />}
            />

            <Route path="sgn-form-a" element={<SgnFormA />} />
            <Route path="form/sgn-form-a" element={<SgnFormAForm />} />
            <Route
              path="form/sgn-form-a/:id/edit"
              element={<SgnFormAEditForm />}
            />
            <Route
              path="sgn-form-a-response/:id"
              element={<SgnFormAResponse />}
            />

            <Route path="sgn-form-b" element={<SgnFormB />} />
            <Route path="form/sgn-form-b" element={<SgnFormBApp />} />
            <Route
              path="sgn-form-b-response/:id"
              element={<SgnFormBResponse />}
            />
            <Route
              path="form/sgn-form-b/:id/edit"
              element={<SgnFormBEditApp />}
            />

            {/* MBS Forms */}
            <Route path="form/mbs-nomination" element={<MbsNomination />} />
            <Route
              path="mbs-nomination-response/:id"
              element={<MbsNominationResponse />}
            />

            {/* Pari Forms */}
            <Route path="form/pari-application-form" element={<PariForm />} />
            <Route
              path="pari-application-form/response/:id"
              element={<PariResponse />}
            />

            {/* Vidya Forms */}
            <Route path="form/vidya-application-form" element={<VidyaForm />} />
            <Route
              path="vidya-application-form/response/:id"
              element={<VidyaResponse />}
            />
            <Route path="list/vidya-applications" element={<VidyaList />} />

            {/* Udaan Forms */}
            <Route path="form/udaan-group-form" element={<UdaanForm />} />
            <Route
              path="udaan-group-form/response/:id"
              element={<UdaanResponse />}
            />

            {/* Eminence Forms */}
            <Route path="list/eminence/q1/group" element={<GrpQ1List />} />
            <Route path="form/eminence/q1/group" element={<GrpQ1Form />} />
            <Route
              path="eminence/q1/group/response/:id"
              element={<GrpQ1Response />}
            />

            <Route path="list/eminence/q1/region" element={<RegQ1List />} />
            <Route path="form/eminence/q1/region" element={<RegQ1Form />} />
            <Route
              path="eminence/q1/region/response/:id"
              element={<RegQ1Response />}
            />

            <Route path="list/eminence/q2/group" element={<GrpQ2List />} />
            <Route path="form/eminence/q2/group" element={<GrpQ2Form />} />
            <Route
              path="eminence/q2/group/response/:id"
              element={<GrpQ2Response />}
            />

            <Route path="list/eminence/q2/region" element={<RegQ3List />} />
            <Route path="form/eminence/q2/region" element={<RegQ3Form />} />
            <Route
              path="eminence/q2/region/response/:id"
              element={<RegQ3Response />}
            />

            <Route path="list/eminence/q3/group" element={<GrpQ3List />} />
            <Route path="form/eminence/q3/group" element={<GrpQ3Form />} />
            <Route
              path="eminence/q3/group/response/:id"
              element={<GrpQ3Response />}
            />

            <Route path="list/eminence/q3/region" element={<RegQ3List />} />
            <Route path="form/eminence/q3/region" element={<RegQ3Form />} />
            <Route
              path="eminence/q3/region/response/:id"
              element={<RegQ3Response />}
            />

            <Route path="list/eminence/q4/group" element={<GrpQ4List />} />
            <Route path="form/eminence/q4/group" element={<GrpQ4Form />} />
            <Route
              path="eminence/q4/group/response/:id"
              element={<GrpQ4Response />}
            />

            <Route path="list/eminence/q4/region" element={<RegQ4List />} />
            <Route path="form/eminence/q4/region" element={<RegQ4Form />} />
            <Route
              path="eminence/q4/region/response/:id"
              element={<RegQ4Response />}
            />

            <Route path="list/eminence/q5/region" element={<RegQ5List />} />
            <Route path="form/eminence/q5/region" element={<RegQ5Form />} />
            <Route
              path="eminence/q5/region/response/:id"
              element={<RegQ5Response />}
            />

            <Route path="list/eminence/q5/group" element={<GrpQ5List />} />
            <Route path="form/eminence/q5/group" element={<GrpQ5Form />} />
            <Route
              path="eminence/q5/group/response/:id"
              element={<GrpQ5Response />}
            />

            <Route path="list/eminence/q6/region" element={<RegQ6List />} />
            <Route path="form/eminence/q6/region" element={<RegQ6Form />} />
            <Route
              path="eminence/q6/region/response/:id"
              element={<RegQ6Response />}
            />

            <Route path="list/eminence/q6/group" element={<GrpQ6List />} />
            <Route path="form/eminence/q6/group" element={<GrpQ6Form />} />
            <Route
              path="eminence/q6/group/response/:id"
              element={<GrpQ6Response />}
            />

            <Route path="list/eminence/h1/ob" element={<ObH1List />} />
            <Route path="form/eminence/h1/ob" element={<ObH1Form />} />
            <Route
              path="eminence/h1/ob/response/:id"
              element={<ObH1Response />}
            />

            <Route
              path="list/eminence/h1/convenor"
              element={<ConvenorH1List />}
            />
            <Route
              path="form/eminence/h1/convenor"
              element={<ConvenorH1Form />}
            />
            <Route
              path="eminence/h1/convenor/response/:id"
              element={<ConvenorH1Response />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
