import Logo from "./logo.png";
import LogoB from "./logoB.png";
import Down from "./down.jpg";
import MannKiBaat from "./mannKiBaat.jpg";
import Founder1 from "./Founder1.png";
import Founder2 from "./Founder2.png";
import Founder3 from "./Founder3.png";
import Founder4 from "./Founder4.png";
import Topbanner from "./Format.png";
import Cursor from "../images/cursor.png";
import MY1CoverEng from "./MY1 Cover Eng.jpg";
import MY1CoverHin from "./MY1 Cover Hin.jpg";
import MY1CoverGuj from "./MY1 Cover Guj.jpg";
import MY2CoverEng from "./MY2 Cover Eng.jpg";
import MY2CoverHin from "./MY2 Cover Hin.jpg";
import MY2CoverGuj from "./MY2 Cover Guj.jpg";
import MY3CoverEng from "./MY3 Cover Eng.jpg";
import MY3CoverHin from "./MY3 Cover Hin.jpg";
import MY3CoverGuj from "./MY3 Cover Guj.jpg";
import MY4CoverEng from "./MY4 Cover Eng.jpg";
import MY4CoverHin from "./MY4 Cover Hin.jpg";
import MY4CoverGuj from "./MY4 Cover Guj.jpg";
import MY5CoverEng from "./MY5 Cover Eng.jpg";
import MY5CoverHin from "./MY5 Cover Hin.jpg";
import MY5CoverGuj from "./MY5 Cover Guj.jpg";
import MY6CoverEng from "./MY6 Cover Eng.jpg";
import MY6CoverHin from "./MY6 Cover Hin.jpg";
import MY6CoverGuj from "./MY6 Cover Guj.jpg";
import MY7CoverEng from "./MY7 Cover Eng.jpg";
import MY7CoverHin from "./MY7 Cover Hin.jpg";
import MY7CoverGuj from "./MY7 Cover Guj.jpg";
import MY8CoverEng from "./MY8 Cover Eng.jpg";
import MY8CoverHin from "./MY8 Cover Hin.jpg";
import MY8CoverGuj from "./MY8 Cover Guj.jpg";
import MY9CoverEng from "./MY9 Cover Eng.jpg";
import MY9CoverHin from "./MY9 Cover Hin.jpg";
import MY9CoverGuj from "./MY9 Cover Guj.jpg";
import MY10CoverEng from "./MY10 Cover Eng.jpg";
import MY10CoverHin from "./MY10 Cover Hin.jpg";
import MY10CoverGuj from "./MY10 Cover Guj.jpg";
import MYSpecialEdition from "./MY Special Edition.jpg";
import VidyaAppE from "./VidyaAppE.jpg";
import VidyaAppH from "./VidyaAppH.jpg";
import VidyaAppG from "./VidyaAppG.jpg";
import VidyaSopE from "./VidyaSopE.jpg";
import VidyaSopH from "./VidyaSopH.jpg";
import VidyaSopG from "./VidyaSopG.jpg";

const Images = {
  Founder1,
  Founder2,
  Founder3,
  Founder4,
  MannKiBaat,
  Down,
  Logo,
  LogoB,
  Topbanner,
  Cursor,
  MY1CoverEng,
  MY1CoverHin,
  MY1CoverGuj,
  MY2CoverEng,
  MY2CoverHin,
  MY2CoverGuj,
  MY3CoverEng,
  MY3CoverHin,
  MY3CoverGuj,
  MY4CoverEng,
  MY4CoverHin,
  MY4CoverGuj,
  MY5CoverEng,
  MY5CoverHin,
  MY5CoverGuj,
  MY6CoverEng,
  MY6CoverHin,
  MY6CoverGuj,
  MY7CoverEng,
  MY7CoverHin,
  MY7CoverGuj,
  MY8CoverEng,
  MY8CoverHin,
  MY8CoverGuj,
  MY9CoverEng,
  MY9CoverHin,
  MY9CoverGuj,
  MY10CoverEng,
  MY10CoverHin,
  MY10CoverGuj,
  MYSpecialEdition,
  VidyaAppE,
  VidyaAppH,
  VidyaAppG,
  VidyaSopE,
  VidyaSopH,
  VidyaSopG,
};

export default Images;
