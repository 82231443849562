import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Images from "../../../images/index";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import "../../Response.css";

function GrpQ6Response() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const getResponse = async () => {
    const res = await axios.get(
      `/api/forms/ventures/eminence-q6-group/${responseId.id}`
    );
    console.log(res.data);
    setResponses(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Eminence Group Q5</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }

    .page{
      page-break-after: always;
    }

    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }

    .section2, .section3{
      margin-top: 30px;
    }

    .section4{
      margin-top: 20px;
    }

    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }

    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }

    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }

    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }

    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }

    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }

    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }

    .toptext {
      margin: 20px 0;
    }

    .logoImage {
      height: 80px;
      margin: 10px;
    }

    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }

    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }

    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }

    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }

    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }

    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }

    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  const mainFormRef = useRef(null);

  return (
    <div>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => printDocument()}
            style={{ margin: "0 5px", marginBottom: "20px" }}
          >
            Download Form
          </Button>

          <h2>Eminence 6th Quarter Application (Groups)</h2>
        </Col>
      </Row>

      <div id="mainForm" ref={mainFormRef} className="centerFormat">
        <div className="fResponseBody">
          <div className="page">
            <div className="bannerYellow">
              <Row className="T-Banner">
                <Col xs={1}>
                  <img
                    src={Images.LogoB}
                    className="logoImage"
                    alt="jsgBanner"
                  ></img>
                </Col>
                <Col xs={11} className="bannerText text-center">
                  <h1
                    style={{
                      margin: "0",
                    }}
                  >
                    JAIN SOCIAL GROUP INT. FEDERATION
                  </h1>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan
                    Road, Mumbai - 400 004.
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Tel.: 022-23870724 | E-mail : office@jsgif.co.in
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "18px",
                      margin: "0",
                      fontWeight: "600",
                    }}
                  >
                    Eminence 6th Quarter Application (Groups)
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <h6
                style={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  marginBottom: "5px",
                }}
              >
                Region - {response.regName}
              </h6>
              <h6
                style={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  marginBottom: "10px",
                }}
              >
                Group - {response.grpName}
              </h6>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Respondant's name</h3>
                <p className="Tsingle-enter">{response.name}</p>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Region Chariman name</h3>
                <p className="Tsingle-enter">{response.regChairName}</p>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Group President Name</h3>
                <p className="Tsingle-enter">{response.grpPresName}</p>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Group President Number</h3>
                <p className="Tsingle-enter">{response.presNum}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  1. TIMING: Time given & No. of Meetings with different theme /
                  Protocol
                </h3>
                <p className="Tsingle-enter">{response.timings}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  2. No of Reports in Mangalyatra till 5th Quarter Your presence
                  of Social Media of JSGIF precisely on JSGIF's FB / Youtube &
                  Instagram Page.
                </h3>
                <p className="Tsingle-enter">{response.socialMedia}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  3. Attendance of Region office bearer / JSGIF office bearer in
                  your group events & your group attendance in Region Meeting /
                  JSGIF Meeting. / any Special meeting with collaboration of
                  more then 2 groups / Youth / Sangini or other region groups
                  that has benefited society on least financial cost
                </h3>
                <p className="Tsingle-enter">{response.collaborationGroup}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  4. No of Eminence form filled out in last 5t Quarter
                </h3>
                <p className="Tsingle-enter">{response.eminenceForms}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  5. Your Presence / Participation in JSGIF Ashray Committee /
                  Events
                </h3>
                <p className="Tsingle-enter">{response.aashray}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  6. Your Participation in JSGIF Runbhoomi Sangini
                </h3>
                <p className="Tsingle-enter">{response.runbhoomi}</p>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              {(() => {
                if (
                  response.picEvent1 !== null ||
                  response.subEvent1 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 1</h3>
                      <h4 className="H4single-enter">{response.subEvent1}</h4>
                      <Image
                        src={response.picEvent1}
                        alt={response.subEvent1}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent2 !== null ||
                  response.subEvent2 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 2</h3>
                      <h4 className="H4single-enter">{response.subEvent2}</h4>
                      <Image
                        src={response.picEvent2}
                        alt={response.subEvent2}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent3 !== null ||
                  response.subEvent3 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 3</h3>
                      <h4 className="H4single-enter">{response.subEvent3}</h4>
                      <Image
                        src={response.picEvent3}
                        alt={response.subEvent3}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent4 !== null ||
                  response.subEvent4 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 4</h3>
                      <h4 className="H4single-enter">{response.subEvent4}</h4>
                      <Image
                        src={response.picEvent4}
                        alt={response.subEvent4}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent5 !== null ||
                  response.subEvent5 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 5</h3>
                      <h4 className="H4single-enter">{response.subEvent5}</h4>
                      <Image
                        src={response.picEvent5}
                        alt={response.subEvent5}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent6 !== null ||
                  response.subEvent6 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 6</h3>
                      <h4 className="H4single-enter">{response.subEvent6}</h4>
                      <Image
                        src={response.picEvent6}
                        alt={response.subEvent6}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent7 !== null ||
                  response.subEvent7 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 7</h3>
                      <h4 className="H4single-enter">{response.subEvent7}</h4>
                      <Image
                        src={response.picEvent7}
                        alt={response.subEvent7}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent8 !== null ||
                  response.subEvent8 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 8</h3>
                      <h4 className="H4single-enter">{response.subEvent8}</h4>
                      <Image
                        src={response.picEvent8}
                        alt={response.subEvent4}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent9 !== null ||
                  response.subEvent9 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 9</h3>
                      <h4 className="H4single-enter">{response.subEvent9}</h4>
                      <Image
                        src={response.picEvent9}
                        alt={response.subEvent9}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent10 !== null ||
                  response.subEvent10 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 10</h3>
                      <h4 className="H4single-enter">{response.subEvent10}</h4>
                      <Image
                        src={response.picEvent10}
                        alt={response.subEvent10}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
            </Row>
            <Row style={{ marginTop: "20px" }}>
              {(() => {
                if (
                  response.picEvent11 !== null ||
                  response.subEvent11 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 11</h3>
                      <h4 className="H4single-enter">{response.subEvent11}</h4>
                      <Image
                        src={response.picEvent11}
                        alt={response.subEvent11}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent12 !== null ||
                  response.subEvent12 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 12</h3>
                      <h4 className="H4single-enter">{response.subEvent12}</h4>
                      <Image
                        src={response.picEvent12}
                        alt={response.subEvent12}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent13 !== null ||
                  response.subEvent13 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 13</h3>
                      <h4 className="H4single-enter">{response.subEvent13}</h4>
                      <Image
                        src={response.picEvent13}
                        alt={response.subEvent13}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent14 !== null ||
                  response.subEvent14 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 14</h3>
                      <h4 className="H4single-enter">{response.subEvent14}</h4>
                      <Image
                        src={response.picEvent14}
                        alt={response.subEvent14}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent15 !== null ||
                  response.subEvent15 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 15</h3>
                      <h4 className="H4single-enter">{response.subEvent15}</h4>
                      <Image
                        src={response.picEvent15}
                        alt={response.subEvent15}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent16 !== null ||
                  response.subEvent16 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 16</h3>
                      <h4 className="H4single-enter">{response.subEvent16}</h4>
                      <Image
                        src={response.picEvent16}
                        alt={response.subEvent16}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent17 !== null ||
                  response.subEvent17 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 17</h3>
                      <h4 className="H4single-enter">{response.subEvent17}</h4>
                      <Image
                        src={response.picEvent17}
                        alt={response.subEvent17}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent18 !== null ||
                  response.subEvent18 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 18</h3>
                      <h4 className="H4single-enter">{response.subEvent18}</h4>
                      <Image
                        src={response.picEvent18}
                        alt={response.subEvent14}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent19 !== null ||
                  response.subEvent19 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 19</h3>
                      <h4 className="H4single-enter">{response.subEvent19}</h4>
                      <Image
                        src={response.picEvent9}
                        alt={response.subEvent19}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
              {(() => {
                if (
                  response.picEvent20 !== null ||
                  response.subEvent20 !== null
                ) {
                  return (
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3 className="H3single-enter">Event 20</h3>
                      <h4 className="H4single-enter">{response.subEvent20}</h4>
                      <Image
                        src={response.picEvent10}
                        alt={response.subEvent20}
                        fluid
                      />
                    </Col>
                  );
                }
              })()}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrpQ6Response;
